<template>
  <div v-bind="$attrs" class="mt-5">
    <h1 class="text-center text-2xl text-blue-dark font-bold">
      Reportes de Progreso
    </h1>
    <div
        class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white users"
    >
      <a-table
          :columns="columns"
          rowKey="id"
          :data-source="reports"
          :pagination="false"
      >
        <div  class="w-11/12 grid grid-cols-1 gap-2" slot="expandedRowRender" slot-scope="record">
          <h3 class="text-justify text-gray-500 font-bold">Informe donde conste la Evaluación de la implementación de las recomendaciones que realice el DCF.</h3>
          <p>{{ record.evaluation_report }}</p>
          <h3 class="text-justify text-gray-500 font-bold">Informe a asamblea general ordinaria u órgano equivalente, sobre las actuaciones adelantadas frente a las recomendaciones o propuestas del DCF, el plan de acción implementado por la entidad, si lo hay, y los casos y asuntos que el DCF haya solicitado llevar a conocimiento de la junta u órgano equivalente.</h3>
          <p>{{ record.assembly_report }}</p>
          <h3 class="text-justify text-gray-500 font-bold">Informe de seguimiento permanente a la gestión del DCF presentado semestralmente a la junta directiva u órgano equivalente, con los resultados y planes de acción implementados para la mejora continua de la atención al consumidor financiero, la mitigación de la causa raíz de las quejas o reclamos, así como el adecuado funcionamiento de la institución del DCF.</h3>
          <p>{{ record.monitoring_report }}</p>
          <BaseDownload
              v-for="(option, index) in record.files"
              :value="option.file_name || option.file_type"
              :key="index"
              :url_download="option.file"
          />
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  name: "AdvanceReportForm",
  components: {
    Accordion,
  },
  props: {
    planId: {
      type: [String, Number],
      required: true,
    },
    recommendationId: {
      type: [String, Number],
      required: true,
    },
    reports: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    columns: [
      {
        title: "Fecha de creción",
        dataIndex: "created_at",
        key: "created_at",
      }
    ]
  })
}
</script>