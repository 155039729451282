<template>
  <div v-bind="$attrs">
    <h1 class="text-center text-2xl text-blue-dark font-bold">Seguimientos</h1>
    <div
      class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white users"
    >
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="tracings"
        :pagination="false"
      >
        <div
          class="w-11/12 grid grid-cols-1 gap-2"
          slot="expandedRowRender"
          slot-scope="record"
        >
          <BaseDownload
            v-for="(option, index) in record.files"
            :value="fileName(option.file)"
            :key="index"
            :url_download="option.file"
          />
        </div>
      </a-table>
    </div>
    <p class="text-justify text-gray-500 font-bold my-5">
      Reporte del estado, aspectos relevantes, cumplimiento de cronograma y
      porcentajes de avance por cada plan de acción.
    </p>
    <a-form-model
      ref="formModel"
      :rules="rules"
      :model="form"
      @submit="onSubmit"
      @submit.native.prevent
    >
      <a-form-model-item prop="description">
        <BaseTextarea
          :required="true"
          :maxlength="1000"
          label="Descripción"
          rows="3"
          v-model="form.description"
        />
      </a-form-model-item>
      <a-form-model-item prop="files">
        <DragDrop
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="support_files"
          :fileList="form.files"
          @files-handler="setFiles"
          :required="true"
          maxMB="20"
        />
      </a-form-model-item>
      <a-form-item class="flex gap-6 justify-center my-5">
        <a-button
          :loading="loading"
          type="primary"
          shape="round"
          html-type="submit"
          class="mr-2"
          >GUARDAR</a-button
        >
        <a-button
          :loading="loading"
          type="danger"
          shape="round"
          ghost
          class="ml-2"
          @click="closeModal"
          >CANCELAR</a-button
        >
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: "TracingForm",
  props: {
    planId: {
      type: [String, Number],
      required: true,
    },
    recommendationId: {
      type: [String, Number],
      required: true,
    },
    tracings: {
      type: Array,
      default: () => [],
    },
  },
  data: (vm) => ({
    loading: false,
    form: {
      description: "",
      files: [],
    },
    rules: {
      description: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          min: 3,
          max: 1000,
          message: "Este campo debe contener entre 3 y 1000 caracteres",
          trigger: "blur",
        },
      ],
      files: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
    },
    columns: [
      {
        title: "Fecha de creción",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "Descripción",
        dataIndex: "description",
        key: "description",
      },
    ],
  }),
  methods: {
    setFiles(files) {
      this.form.files = files;
    },
    onSubmit(e) {
      e.preventDefault();
      this.$refs.formModel.validate((valid) => {
        if (valid) {
          this.onCreate();
        } else {
          return false;
        }
      });
    },
    onCreate() {
      this.loading = true;
      this.$api
        .tracingsRecommendations(this.recommendationId, {
          form: {
            description: this.form.description,
            action_plan: `${this.planId}`,
          },
          files: this.form.files,
        })
        .then((response) => this.$emit("on-success", response.data))
        .then(() => {
          this.$refs.formModel.resetFields();
        })
        .then(() =>
          this.showToast("success", "Seguimiento guardado correctamente")
        )
        .catch(() =>
          this.showToast("info", "Algunos datos no se guardaron correctamente.")
        )
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit("on-cancel", true);
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    fileName(url) {
      let data = (url || "").split("/").pop();
      return (data || "").split("?").shift();
    },
  },
};
</script>